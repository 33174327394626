import { ModelSelect, BasicSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import { format } from 'date-fns';
import ModulesList from '@/app/components/admin/responsibility/searchModule';
import store from '../../../../../../store';
import applyReceipt from '../../../../arReceivables/applyReceipt/applyReceiptDetails';
export default {
  name: 'createReceipt',
  components: {
    ModelSelect,
    DatePicker,
    BasicSelect,
    ModulesList,
    applyReceipt
  },
  props: ['receiptHdrId'],
  watch: {
    selectedApplicationType: function () {
      if (this.selectedApplicationType.value === 'AUTOMATIC') {
        this.calculateAdjustmentAmount();
      } else {
        this.remainingAmout = parseFloat(
          this.createReceiptForm.instrumentDetails.amount
        );
        this.remainingTdsAmout = parseFloat(this.createReceiptForm.instrumentDetails.tdsAmount);
        this.modifiedOutStandingDataList.forEach(key => {
          key.adj_amount = 0;
          key.tds_amount = 0;
        });
        this.outStandingTableFields[0].key = 'select';
      }
    },
    customerPerPage: function () {
      this.customerCurrentPage = 1;
      // this.getOtherLovByProjectId();
      if (this.selectedModule.name === 'FMS') {
        this.getReceiptCustomer();
      } else {
        this.getProjectBasedCustomerSites();
      }
    },
    customerCurrentPage: function () {
      if (this.selectedModule.name === 'FMS') {
        this.getReceiptCustomer();
      } else {
        this.getProjectBasedCustomerSites();
      }
    },
    // selectedProject: function() {
    //   if (this.selectedProject.value) {
    //     this.lovFieldName = appStrings.FMSLOV.FMSPROJECT;
    //     this.lovId = this.selectedProject.value;
    //     this.getOtherLovByProjectId();
    //   }
    // },
    selectReceiptNature: function () {
      // this.unitDetailsVisible =
      //   this.selectReceiptNature.value === 'identified' &&
      //   this.createReceiptForm.leAndOuDetails.selectedReceiptAgainst.value ===
      //     'SUBUNIT'
      //     ? true
      //     : false;
      this.costomerDetailsVisible =
        this.selectReceiptNature.value === 'unidentified' ? false : true;
    }
  },
  data() {
    return {
      vsetCode: null,
      setTimeout: null,
      showValueSetModal: false,
      unsubscribe: null,
      valueSetModal: false,
      parent_value_set_id: null,
      isBusy: true,
      advanceAmountWarning: false,
      editMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showReceiptPreview: false,
      remainingAmout: 0.0,
      totalOsAmout: null,
      showAdjWarning: false,
      warningMessage: null,
      customerPerPage: commonHelper.perPageRecord,
      customerCurrentPage: 1,
      customerTotalRows: null,
      unitDetailsVisible: true,
      costomerDetailsVisible: true,
      loader: false,
      openOuModal: false,
      lovFieldName: null,
      lovId: null,
      showCreateReceiptModel: false,
      dummyOptions: [
        {
          value: null,
          text: '--select--'
        }
      ],
      selectedDummy: {
        value: null,
        text: '--select--'
      },
      selectReceiptNature: {
        value: 'identified',
        text: 'Identified'
      },
      receiptNatureList: [
        {
          value: 'identified',
          text: 'Identified'
        },
        {
          value: 'unidentified',
          text: 'Unidentified'
        }
      ],
      keyValue: {
        value: null,
        text: null
      },
      keyValueNoRecord: {
        value: null,
        text: 'no record found'
      },
      selectedProject: {
        value: null,
        text: null
      },
      selectedPlanType: {
        value: null,
        text: null
      },
      paymentModeList: [],
      receiptMethodList: [],
      receiptMethodNameList: [],
      selectedMethodName: {
        value: null,
        text: null
      },
      selectedPaymentMode: {
        value: null,
        text: null
      },
      bankList: [],
      selectedBank: {
        value: null,
        text: null
      },
      applicationTypeList: [],
      selectedApplicationType: {
        value: null,
        text: null
      },
      createReceiptForm: {
        entity_type: null,
        entity_id: null,
        module: null,
        module_id: null,
        receipt_hdr_id: 0,
        leAndOuDetails: {
          legalEntity: {
            id: null,
            text: null
          },
          orgUnit: {
            id: null,
            text: null
          },
          receiptAgainstList: [
            {
              value: null,
              text: null
            }
          ],
          selectedReceiptAgainst: {
            value: null,
            text: null
          }
        },
        leaseUnitDetails: {
          leaseProject: {
            value: null,
            text: null
          },
          leaseTower: {
            value: null,
            text: null
          },
          leaseFloor: {
            value: null,
            text: null
          },
          leaseUnit: {
            value: null,
            text: null
          },
          leaseUnitList: []
        },
        unitDetails: {
          // prj_name: null,
          // sector_name: null,
          // pocket_name: null,
          // tower_name: null,
          // floor_name: null,
          // unit_name: null,
          // sub_unit_name: null,
          // projectList: [],
          // sectorList: [],
          // selectedSector: {
          //   value: null,
          //   text: null
          // },
          // pocketList: [],
          // selectedPocket: {
          //   value: null,
          //   text: null
          // },
          // towerList: [],
          selectedTower: {
            value: null,
            text: null
          },
          // floorList: [],
          selectedFloor: {
            value: null,
            text: null
          },
          // unitList: [],
          selectedUnit: {
            value: null,
            text: null
          },
          // subUnitList: [],
          selectedSubUnit: {
            value: null,
            text: null
          }
        },
        customerDetails: {
          customer_name: null,
          customer_id: null,
          complete_address: null,
          address_id: null,
          site_name: null,
          customer_site_id: null,
          lease_prj_unit_id: null,
          unit_name: null,
          meter_no:null
        },
        instrumentDetails: {
          receipt_num: null,
          currency_code: null,
          currency_id: null,
          exchange_rate: 1,
          exchange_type: 'Corporate',
          value_date: format(new Date(), appStrings.DATEFNSFORMAT),
          gl_date: format(new Date(), appStrings.DATEFNSFORMAT),
          instrument_date: format(new Date(), appStrings.DATEFNSFORMAT),
          amount: null,
          instrument_num: null,
          branch: null,
          narration: null,
          instrument_status: null,
          clearing_date: null,
          reason: null,
          reasonVset: null,
          reversalComments: null,
          tdsAmount: null,
          tdsReceiptMethod: {
            text: null,
            value: null
          },
          tdsReceiptMethodList: [],
          tdsReceiptNo: null,
          receipt_type_vset: null,
          receipt_type_meaning: null,
          crystalReferenceNumber: null
        },
        receiptMethodDetails: {
          auto_apply: true,
          party_id: 0,
          branch_name: null,
          bank_branch_id: null,
          account_name: null,
          bank_account_id: null,
          party_receipt_method_id: null,
          receipt_mrthods_name: null,
          bank_name: null
        }
      },
      outStandingDataList: [],
      modifiedOutStandingDataList: [],
      adJustmentDataList: [
        {
          category_name: null,
          receipt_hdr_id: null,
          tax_cat_id: null,
          receipt_adv_id: 0,
          amount: 0.0
        }
      ],
      componentGroupList: [],
      componentList: [],
      outStandingTableFields: [
        {
          key: 'select',
          stickyColumn: true
        },
        {
          key: 'line_type'
        },
        {
          key: 'trx_number'
        },
        {
          key: 'trx_date'
        },
        {
          key: 'description'
        },
        {
          key: 'trx_amount',
          label: 'Trx Amt',
          class: 'text-right'
        },
        {
          key: 'applied_amount',
          label: 'App Amt',
          class: 'text-right'
        },
        {
          key: 'outstanding_amount',
          label: 'OS Amt',
          class: 'text-right'
        },
        {
          key: 'adj_amount',
          label: 'Adj Amt',
          class: 'adj-amount-col'
        },
        {
          key: 'tds_amount',
          label: 'TDS Amt',
          class: 'tds-amount-col'
        }
      ],
      adjustmentTableFields: [
        {
          key: 'category_name',
          label: 'Tax Category'
        },
        {
          key: 'total_amount'
        },
        {
          key: 'amount'
        },
        {
          key: 'tax_amount'
        }
      ],
      fmsCompGroupList: [],
      showCustomerModal: false,
      customerName: '',
      allCustomer: false,
      customerList: [],
      customerFields: [
        {
          key: 'customer_id',
          class: 'd-none'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'customer_site_name'
        },
        {
          key: 'customer_site_id',
          class: 'd-none'
        },
        {
          key: 'bill_to_address'
        },
        {
          key: 'address_id',
          class: 'd-none'
        }, 
       
      ],
      leaseCustomerFields: [
        'customer_id',
        'customer_name',
        'customer_site_id',
        'customer_site_name',
        'bill_to_address'
      ],
      showTaxCategoryFlag: false,
      taxCategoryList: [],
      taxCategoryFields: [
        {
          key: 'category_name'
        },
        {
          key: 'tax_rate'
        }
      ],
      accountingDetailsList: [],
      accountdingDetailsField: [
        {
          key: 'receipt_num'
        },
        {
          key: 'ccid',
          label: 'CCID'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'period_name',
          label: 'Period'
        },
        {
          key: 'transaction_dr'
        },
        {
          key: 'transaction_cr'
        },
        {
          key: 'base_dr'
        },
        {
          key: 'base_cr'
        },
        {
          key: 'account_class'
        },
        {
          key: 'posting_flag'
        }
      ],
      arReceiptNotesList: [
        {
          note_id: 0,
          notes: null,
          creation_date: commonHelper.formattedDate(new Date()),
          created_by: this.getUserName()
        }
      ],
      arReceiptNotesField: [
        {
          key: 'notes'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        }
      ],

      showHideModule: false,
      selectedModule: {
        name: null,
        id: null
      },
      reverseReason: null,
      reverseReasonVsetCode: null,
      reverseRemark: null,
      reverseDate: null,
      isReveseSuccess: false,
      showReveseAlert: false,
      reverseResponseMsg: '',
      reverseResponseText: null,
      depVsetParam: null,
      leaseByFmsPrjId: null,
      project: 'NFA_FMS_PROJECT',
      tower: 'NFA_FMS_TOWER',
      floor: 'NFA_FMS_FLOOR',
      unit: 'NFA_FMS_UNIT',
      isEditable: this.$store.state.shared.actionName === 'add',
      isSubunit: true,
      showApplyReceiptModal: false,
      sendObj: {}, remainingTdsAmout: null
    };
  },
  mounted() {
    this.getAllLeaseUnits();
    // this.getOrganizationList(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
    this.getReceiptAgainast();
    this.getCurrency();
    this.getReceiptApplicatonTypes();
    // this.getAdjComponentGroupList();
    this.getTaxCategory();

    if (this.receiptHdrId) {
      this.loader = true;
      this.editMode = true;
      this.createReceiptForm.receipt_hdr_id = this.receiptHdrId;
      this.getReceiptDetailsById(this.receiptHdrId);
      this.getRemainingAmountForOs();
    } else {
      this.setDefaultReceiptType();
      this.setDefaultPaymentMode();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && !this.createReceiptForm.receipt_hdr_id) {
          this.showReceiptPreview = true;
        }
        if (actionName === 'save' && this.receiptHdrId && !this.showApplyReceiptModal) {
          this.addEditReceiptNotes();
          this.updateNarration();
          // this.showReceiptPreview = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', {
            id: this.createReceiptForm.receipt_hdr_id
          });
        }
        if (actionName === 'add') {
          if (confirm('Do you want to clear all fields?')) {
            this.createNewReceipt();
          }
        }
      }
    });
  },
  methods: {
    getUserName() {
      const tempName = store.state.auth.userName;
      const name = tempName.slice(tempName.lastIndexOf('-') + 1);
      return name;
    },
    updateNarration() {
      const payload = {
        receiptHdrId: this.receiptHdrId,
        ar_narration: this.createReceiptForm.instrumentDetails.narration
      };
      this.loader = true;
      this.$store
        .dispatch('receivables/updateNarration', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    addEditReceipt() {
      if (
        this.createReceiptForm.leAndOuDetails.legalEntity.id &&
        this.createReceiptForm.leAndOuDetails.orgUnit.id &&
        this.selectedModule.id &&
        ((this.selectReceiptNature.value === 'identified' &&
          this.createReceiptForm.customerDetails.customer_id) ||
          (this.selectReceiptNature.value !== 'identified' &&
            !this.createReceiptForm.customerDetails.customer_id)) &&
        this.createReceiptForm.entity_id &&
        this.createReceiptForm.entity_type &&
        this.createReceiptForm.instrumentDetails.receipt_type_meaning &&
        this.selectedPaymentMode.value &&
        this.createReceiptForm.instrumentDetails.amount &&
        this.createReceiptForm.instrumentDetails.narration &&
        this.checkTdsAmountParam() &&
        this.selectedMethodName.value
      ) {
        const filterOutstanding = this.modifiedOutStandingDataList.filter(
          data => data.adj_amount
        );
        const filterTdsOutstanding = this.modifiedOutStandingDataList.filter(
          data => data.tds_amount
        );
        const filterAdvanceReceipt = this.adJustmentDataList.filter(
          advanceBill => advanceBill.amount !== null
        );
        const advanceReceipt = filterAdvanceReceipt.map(advanceBill => {
          return {
            amount: advanceBill.total_amount,
            receipt_adv_id: 0,
            tax_cat_hdr_id: advanceBill.tax_cat_id
          };
        });
        if (this.selectedModule.name === "FMS") {
          this.selectedPlanType.value === "LEASE_UNIT" ? this.createReceiptForm.entity_type = "LEASE_PRJ" : this.createReceiptForm.entity_type = "FMS_PRJ";
        }
        const payload = {
          module_id: this.selectedModule.id,
          receipt_hdr_id: this.createReceiptForm.receipt_hdr_id,
          le_id: this.createReceiptForm.leAndOuDetails.legalEntity.id,
          ou_id: this.createReceiptForm.leAndOuDetails.orgUnit.id,
          // customer_id: this.unitDetailsVisible
          //   ? this.createReceiptForm.customerDetails.customer_id
          //   : null,
          customer_id: this.createReceiptForm.customerDetails.customer_id
            ? this.createReceiptForm.customerDetails.customer_id
            : null,
          // customer_site_id: this.unitDetailsVisible
          //   ? this.createReceiptForm.customerDetails.customer_site_id
          //   : null,
          customer_site_id: this.createReceiptForm.customerDetails.customer_site_id
            ? this.createReceiptForm.customerDetails.customer_site_id
            : null,
          site_address_id: this.createReceiptForm.customerDetails.address_id
            ? this.createReceiptForm.customerDetails.address_id
            : null,
          currency_id: this.createReceiptForm.instrumentDetails.currency_id,
          // meter_no:this.createReceiptForm.customerDetails.meter_no,
          application_type_vset: this.selectedApplicationType.value,
          // auto_apply: this.createReceiptForm.receiptMethodDetails.auto_apply,
          bank_branch: this.createReceiptForm.instrumentDetails.branch,
          bank_vset: this.selectedBank.value,
          comments: this.createReceiptForm.instrumentDetails.narration,
          exchange_rate: this.createReceiptForm.instrumentDetails.exchange_rate,
          entity_id: this.createReceiptForm.entity_id,
          entity_type: this.createReceiptForm.entity_type,
          plan_unit_type: this.selectedPlanType.value,
          unit_id: this.selectedPlanType.value === 'LEASE_UNIT' ? this.createReceiptForm.unitDetails.selectedUnit.value : this.createReceiptForm.unitDetails.selectedSubUnit.value,
          gl_date: format(
            new Date(this.createReceiptForm.instrumentDetails.gl_date),
            appStrings.DATEFNSFORMAT
          ),
          instrument_amount: this.createReceiptForm.instrumentDetails.amount,
          instrument_date: format(
            new Date(this.createReceiptForm.instrumentDetails.instrument_date),
            appStrings.DATEFNSFORMAT
          ),
          instrument_num: this.createReceiptForm.instrumentDetails
            .instrument_num,
          party_receipt_method_id: this.selectedMethodName.value,
          receipt_mode_vset: this.selectedPaymentMode.value,
          value_date: format(
            new Date(this.createReceiptForm.instrumentDetails.value_date),
            appStrings.DATEFNSFORMAT
          ),
          tds_amount: this.createReceiptForm.instrumentDetails.tdsAmount,
          tds_receipt_method_id: this.createReceiptForm.instrumentDetails
            .tdsReceiptMethod.value,
          create_receipt_advance:
            this.unitDetailsVisible &&
              this.modifiedOutStandingDataList.length > 0
              ? advanceReceipt
              : [],
          create_receipt_adjustment: this.unitDetailsVisible
            ? filterOutstanding.map(outStanding => {
              return {
                bill_adj_amount: outStanding.adj_amount,
                receipt_adj_id: 0,
                trx_dtl_id: outStanding.trx_dtl_id,
                trx_hdr_id: outStanding.trx_hdr_id
              };
            })
            : [],
          create_receipt_tds_adjustment: this.unitDetailsVisible
            ? filterTdsOutstanding.map(tdsOutStanding => {
              return {
                bill_adj_tds_amount: tdsOutStanding.tds_amount,
                receipt_adj_id: 0,
                trx_dtl_id: tdsOutStanding.trx_dtl_id,
                trx_hdr_id: tdsOutStanding.trx_hdr_id
              };
            })
            : [],
          receipt_type_vset: this.createReceiptForm.instrumentDetails
            .receipt_type_vset
        };
        this.loader = true;
        this.$store
          .dispatch('receivables/addEditReceipt', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.showReceiptPreview = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              const result = response.data.data;
              this.createReceiptForm.receipt_hdr_id = result.receipt_hdr_id;
              this.createReceiptForm.instrumentDetails.receipt_num =
                result.receipt_num;
              this.createReceiptForm.instrumentDetails.tdsReceiptNo =
                result.tds_receipt_no;
              this.getOutStandingList();
              this.sendObj = result;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Please select all mandatory parameters');
      }
    },
    getTrxEntityTypeByModuleId(moduleId) {
      this.$store
        .dispatch('receivables/getTrxEntityTypeByModuleId', moduleId)
        .then(response => {
          if (response.status === 200) {
            this.createReceiptForm.entity_type = response.data.data;
          }
        });
    },
    getReceiptDetailsById(receiptId) {
      this.$store
        .dispatch('receivables/getReceiptDetails', receiptId)
        .then(response => {
          this.loader = false;
          if (response.data.data) {
            const result = response.data.data;
            this.sendObj = result;
            this.selectedModule.name = result.module;
            this.selectedModule.id = result.module_id;
            this.selectReceiptNature = {
              value: result.receipt_nature
                ? result.receipt_nature.toLowerCase()
                : result.receipt_nature,
              text: result.receipt_nature
            };
            this.createReceiptForm.receiptMethodDetails.party_receipt_method_id =
              result.party_receipt_method_id;
            this.createReceiptForm.leAndOuDetails.legalEntity.text =
              result.le_name;
            this.createReceiptForm.leAndOuDetails
              .selectedReceiptAgainst.value = result.receipt_against;
            this.createReceiptForm.leAndOuDetails
              .selectedReceiptAgainst.text = result.receipt_against_meaning;
            this.createReceiptForm.leAndOuDetails.legalEntity.id = result.le_id;
            this.createReceiptForm.leAndOuDetails.orgUnit.text = result.ou_name;
            this.createReceiptForm.leAndOuDetails.orgUnit.id = result.ou_id;
            this.createReceiptForm.customerDetails.customer_name =
              result.customer_name;
            this.createReceiptForm.customerDetails.site_name =
              result.customer_site_name;
            this.createReceiptForm.customerDetails.complete_address =
              result.bill_to_address;
            this.createReceiptForm.customerDetails.customer_id =
              result.customer_id;
            this.createReceiptForm.customerDetails.customer_site_id =
              result.customer_site_id;
            this.createReceiptForm.customerDetails.address_id =
              result.site_address_id;
            this.createReceiptForm.instrumentDetails.receipt_num =
              result.receipt_num;
            this.createReceiptForm.instrumentDetails.currency_code =
              result.currency;
            this.createReceiptForm.instrumentDetails.currency_id =
              result.currency_id;
            this.createReceiptForm.instrumentDetails.exchange_rate =
              result.exchange_rate;
             this.createReceiptForm.customerDetails.meter_no =result.meter_no;
            this.selectedPaymentMode = {
              value: result.payment_mode_vset,
              text: result.payment_mode
            };
            this.createReceiptForm.instrumentDetails.instrument_num =
              result.instrument_num;
            this.createReceiptForm.instrumentDetails.instrument_date =
              result.instrument_date;
            this.createReceiptForm.instrumentDetails.amount =
              result.instrument_amount;
            this.createReceiptForm.instrumentDetails.value_date =
              result.value_date;
            this.createReceiptForm.instrumentDetails.gl_date = result.gl_date;
            this.createReceiptForm.instrumentDetails.narration =
              result.narration;
            this.selectedBank = {
              value: result.bank_vset,
              text: result.bank
            };
            this.createReceiptForm.instrumentDetails.branch = result.branch;
            this.createReceiptForm.instrumentDetails.instrument_status =
              result.instrument_status_vset_meaning;
            this.createReceiptForm.instrumentDetails.clearing_date =
              result.clearing_gl_date;
            this.createReceiptForm.instrumentDetails.tdsAmount =
              result.tds_amount;
            this.createReceiptForm.instrumentDetails.tdsReceiptNo =
              result.tds_receipt_no;
            this.createReceiptForm.instrumentDetails.tdsReceiptMethod = {
              text: result.tds_bank_account_name,
              value: result.tds_receipt_method_id
            };
            this.createReceiptForm.instrumentDetails.receipt_type_meaning =
              result.receipt_type_meaning;
            this.createReceiptForm.instrumentDetails.reason =
              result.reversal_reason;
            this.createReceiptForm.instrumentDetails.reasonVset =
              result.reversal_reason_vset;
            this.createReceiptForm.instrumentDetails.reversalComments =
              result.reversal_comments;
              this.createReceiptForm.instrumentDetails.crystalReferenceNumber =result.crystal_reference_number;
            if (this.selectedModule.name === 'FMS') {
              // this.createReceiptForm.unitDetails.prj_name = result.prj_name;
              // this.createReceiptForm.unitDetails.sector_name =
              // result.sector_name;
              // this.createReceiptForm.unitDetails.pocket_name =
              // result.pocket_name;
              // this.createReceiptForm.unitDetails.tower_name = result.tower_name;
              // this.createReceiptForm.unitDetails.floor_name = result.floor_name;
              // this.createReceiptForm.unitDetails.unit_name = result.unit_name;
              // this.createReceiptForm.unitDetails.sub_unit_name =
              // result.sub_unit_name;
              // this.createReceiptForm.unitDetails.selectedSector = {
              //   value: result.sector_id,
              //   text: result.sector_name
              // };
              // this.createReceiptForm.unitDetails.selectedPocket = {
              //   value: result.pocket_id,
              //   text: result.pocket_name
              // };
              this.selectedPlanType = {
                value: result.plan_unit_type,
                text: result.plan_unit_type_meaning
              };
              this.selectedProject = {
                value: result.prj_id,
                text: result.prj_name
              };
              this.createReceiptForm.unitDetails.selectedTower = {
                value: result.tower_id,
                text: result.tower_name
              };
              this.createReceiptForm.unitDetails.selectedFloor = {
                value: result.floor_id,
                text: result.floor_name
              };
              this.createReceiptForm.unitDetails.selectedUnit = {
                value: result.unit_id,
                text: result.unit_name
              };
              this.createReceiptForm.unitDetails.selectedSubUnit = {
                value: result.sub_unit_id,
                text: result.sub_unit_name
              };
            } else if (this.selectedModule.name === 'Lease Management') {
              this.createReceiptForm.leaseUnitDetails = {
                leaseProject: {
                  value: result.prj_id,
                  text: result.prj_name
                },
                leaseTower: {
                  value: result.tower_id,
                  text: result.tower_name
                },
                leaseFloor: {
                  value: result.floor_id,
                  text: result.floor_name
                },
                leaseUnit: {
                  value: result.unit_id,
                  text: result.unit_name
                }
              };
            }
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    getReceiptAdvance() {
      const payload = {
        receipt_hdr_id: this.receiptHdrId
      };
      this.$store
        .dispatch('receivables/getReceiptAdvanceData', payload)
        .then(response => {
          this.adJustmentDataList = response.data.data;
          this.adJustmentDataList = this.adJustmentDataList.map(elem => {
            elem.total_amount = elem.amount;
            elem.tax_amount = parseFloat(
              (elem.total_amount * elem.tax_rate) / 100
            )
              ? parseFloat((elem.total_amount * elem.tax_rate) / 100)
              : null;
            elem.amount = (elem.total_amount - elem.tax_amount).toFixed(2)
              ? (elem.total_amount - elem.tax_amount).toFixed(2)
              : null;
            return elem;
          });
        });
    },
    getTaxCategory() {
      this.$store.dispatch('fms/getTaxCatory').then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          // const getValueAndText = results.map(type => {
          //   return {
          //     value: type.tax_cat_hdr_id,
          //     text: type.category_name
          //   };
          // });
          this.taxCategoryList = results;
          // this.adJustmentDataList[0].taxCategoryList = getValueAndText;
          // this.adJustmentDataList[0].selectedCategory = getValueAndText[0];
        }
      });
    },
    openTaxCatModal(flag, index) {
      this.showTaxCategoryFlag = flag;
      this.taxIndex = index;
    },
    selectedTaxRow(item) {
      this.adJustmentDataList[this.taxIndex].category_name = item.category_name;
      this.adJustmentDataList[this.taxIndex].tax_cat_id = item.tax_cat_hdr_id;
      this.adJustmentDataList[this.taxIndex].tax_rate = item.tax_rate;
      this.showTaxCategoryFlag = false;
      this.calculateAdvanceAmount();
    },
    // getOrganizationList(orgType) {
    //   const payload = {
    //     valueSetName: orgType,
    //     dependentVariable: {}
    //   };
    //   if (orgType === appStrings.VALUESETTYPE.OU_LIST) {
    //     payload.dependentVariable.le_id = this.createReceiptForm.leAndOuDetails.legalEntity.id;
    //   } else {
    //     payload.dependentVariable.user_id = store.state.auth.userId;
    //     payload.dependentVariable.res_id = store.state.shared.responsibilityId;
    //   }
    //   this.$store
    //     .dispatch('shared/getCommonVSetData', payload)
    //     .then(response => {
    //       const result = response.data.data.data[0];
    //       if (orgType === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
    //         this.createReceiptForm.leAndOuDetails.legalEntity.text =
    //           result.org_name;
    //         this.createReceiptForm.leAndOuDetails.legalEntity.id =
    //           result.org_id;
    //         this.getProjectList();
    //         this.getReceiptCustomer();
    //       } else {
    //         this.createReceiptForm.leAndOuDetails.orgUnit.text =
    //           result.org_name;
    //         this.createReceiptForm.leAndOuDetails.orgUnit.id = result.org_id;
    //       }
    //       if (orgType === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
    //         this.getOrganizationList(appStrings.VALUESETTYPE.OU_LIST);
    //       }
    //     })
    //     .catch(err => {
    //       this.err = err;
    //     });
    // },
    getReceiptCustomer() {
      this.customerList = [];
      const payload = {
        _page: this.customerCurrentPage - 1,
        _limit: this.customerPerPage,
        module_id: this.selectedModule.id,
        le_id: this.createReceiptForm.leAndOuDetails.legalEntity.id
          ? this.createReceiptForm.leAndOuDetails.legalEntity.id
          : null,
        ou_id: this.createReceiptForm.leAndOuDetails.orgUnit.id ? this.createReceiptForm.leAndOuDetails.orgUnit.id : null,
        entity_id: this.createReceiptForm.entity_id,
        customer_name: this.customerName
      };
      if (!this.editMode) {
        this.createReceiptForm.customerDetails = {
          customer_name: null,
          customer_id: null,
          complete_address: null,
          address_id: null,
          site_name: null,
          customer_site_id: null,
          lease_prj_unit_id: null,
          unit_name: null
        };
        this.$store
          .dispatch('receivables/getReceiptCustomerDetails', payload)
          .then(response => {
            if (response.status === 200) {
              const result = response.data.data;
              if (result.page && result.page.length > 0) {
                this.isBusy = false;
                this.customerList = result.page;
                this.customerTotalRows = result.total_page;
                this.createReceiptForm.customerDetails = result.page[0];
                this.getOutStandingList();
              }
            }
          });
      }
    },
    getCurrency() {
      const payload = {
        currentPage: 0,
        perPage: 10,
        search: 'inr'
      };
      this.$store
        .dispatch('currency/getCurrencyPagination', payload)
        .then(response => {
          const results = response.data.data.page;
          if (results) {
            this.createReceiptForm.instrumentDetails.currency_code =
              results[0].currency_code;
            this.createReceiptForm.instrumentDetails.currency_id =
              results[0].currency_id;
          }
        });
    },
    getPaymentMode() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'PAYMENT_MODE')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.paymentModeList = getValueAndText;
          }
        });
    },
    getReceiptMethodsData() {
      if (
        !this.editMode ||
        (this.editMode &&
          this.createReceiptForm.receiptMethodDetails.party_receipt_method_id)
      ) {
        this.receiptMethodList = [];
        this.receiptMethodNameList = [];
        this.createReceiptForm.receiptMethodDetails.bank_name = null;
        this.createReceiptForm.receiptMethodDetails.branch_name = null;
        this.createReceiptForm.receiptMethodDetails.account_name = null;
        this.createReceiptForm.receiptMethodDetails.auto_apply = true;
        const payload = {
          module_id: this.selectedModule.id,
          le_id: this.createReceiptForm.leAndOuDetails.legalEntity.id
            ? this.createReceiptForm.leAndOuDetails.legalEntity.id
            : null,
          project_id: this.selectedProject.value
            ? this.selectedPlanType.value === 'LEASE_UNIT'
              ? this.leaseByFmsPrjId
              : this.selectedProject.value
            : this.createReceiptForm.leaseUnitDetails.leaseProject.value,
          receipt_mode_vset_code:
            this.selectedPaymentMode.value !== null
              ? this.selectedPaymentMode.value
              : null,
          party_receipt_method_id: this.createReceiptForm.receiptMethodDetails
            .party_receipt_method_id
        };
        if (!this.checkIntrumentFomrValidation()) {
          this.loader = true;
          this.$store
            .dispatch('receivables/getReceiptMethodsDetail', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                const result = response.data.data;
                this.receiptMethodList = result;
                this.receiptMethodNameList = response.data.data.map(method => {
                  return {
                    value: method.party_receipt_method_id,
                    text: method.receipt_methods_name
                  };
                });
                if (this.receiptMethodList.length > 0) {
                  this.getSelectedMethodNameData(this.receiptMethodList);
                }
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      }
    },
    getReceiptApplicatonTypes() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'RECEIPT_APPLICATION_TYPE')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.applicationTypeList = getValueAndText;
            this.selectedApplicationType =
              getValueAndText && getValueAndText.length > 0
                ? getValueAndText[1]
                : this.keyValue;
          }
        });
    },
    getOutStandingList() {
      let entityId = null
      if (this.selectedModule.name === 'FMS') {
        entityId = this.selectedProject.value;
      } else if (this.selectedModule.name === 'Lease Management') {
        entityId = this.createReceiptForm.leaseUnitDetails.leaseProject.value
      }
      const payload = {
        module_id: this.selectedModule.id,
        customer_id: this.createReceiptForm.customerDetails.customer_id,
        // customer_site_id: this.createReceiptForm.customerDetails
        //   .customer_site_id
        entity_id: this.createReceiptForm.receipt_hdr_id > 0 ? entityId : this.createReceiptForm.entity_id
      };
      this.$store
        .dispatch('receivables/getOutStandingList', payload)
        .then(response => {
          if (response.status === 200) {
            if (this.editMode) {
              this.getReceiptAdjDetails(response.data.data.outstandings);
            } else {
              this.outStandingDataList = response.data.data.outstandings;
            }
            this.totalOsAmout = response.data.data.total_os_amt;
            this.totalOsAmout = parseFloat(this.totalOsAmout).toFixed(2);
          }
        });
    },
    getReceiptAdjDetails(modifiedOutStandingDataList) {
      this.$store
        .dispatch(
          'receivables/getReceiptAdjustmentDetails',
          this.createReceiptForm.receipt_hdr_id
        )
        .then(response => {
          if (response.status === 200) {
            const adjustmentDtl = response.data.data;
            this.setAjustmentData(adjustmentDtl, modifiedOutStandingDataList);
          }
        });
    },
    setAjustmentData(adjustmentDtl, modifiedOutStandingDataList) {
      modifiedOutStandingDataList.forEach((elem, index) => {
        const key = adjustmentDtl[index];
        if (key && elem.trx_hdr_id == key.trx_hdr_id) {
          elem.adj_amount = key.adj_amount;
          elem.receipt_adj_id = key.receipt_adj_id;
        } else {
          elem.adj_amount = 0.0;
        }
      });
      this.modifiedOutStandingDataList = modifiedOutStandingDataList;
    },
    selectedRecpMethodFun(event) {
      this.selectedMethodName.value = event.value;
      this.selectedMethodName.text = event.text;
      this.getSelectedMethodNameData(this.receiptMethodList);
    },
    getSelectedMethodNameData(receiptMethodList) {
      if (receiptMethodList && receiptMethodList.length > 1) {
        const findReceiptMethodObj = receiptMethodList.find(
          method =>
            method.party_receipt_method_id === this.selectedMethodName.value
        );
        if (findReceiptMethodObj) {
          this.createReceiptForm.receiptMethodDetails = findReceiptMethodObj;
        }
      } else {
        this.createReceiptForm.receiptMethodDetails =
          receiptMethodList && receiptMethodList[0];
        this.selectedMethodName.value =
          receiptMethodList && receiptMethodList[0].party_receipt_method_id;
        this.selectedMethodName.text =
          receiptMethodList && receiptMethodList[0].receipt_methods_name;
      }
      this.createReceiptForm.receiptMethodDetails.auto_apply = true;
    },
    selectedComGroup(event, index) {
      this.adJustmentDataList[index].selectedCompoentGroup = event;
      const findValueSet = this.fmsCompGroupList.find(
        compGroup => compGroup.value_code === event.value
      );
      this.getAdjComponentList(findValueSet.value_set_dtl_id, index);
    },
    openCustomerModal(flag) {
      this.showCustomerModal = flag;
    },
    hideModel() {
      this.showCreateReceiptModel = false;
      this.showReceiptPreview = false;
      this.$emit('hideCreateReceiptComp');
    },
    // selectedTowerFun(towerVal) {
    //   this.lovFieldName = appStrings.FMSLOV.FMSTOWER;
    //   this.lovId = towerVal.value;
    //   this.createReceiptForm.unitDetails.selectedTower = towerVal;
    //   this.getOtherLovByProjectId();
    // },
    // selectedFloorFun(floorVal) {
    //   this.lovFieldName = appStrings.FMSLOV.FMSFLOOR;
    //   this.createReceiptForm.unitDetails.selectedFloor = floorVal;
    //   this.lovId = floorVal.value;
    //   this.getOtherLovByProjectId();
    // },
    // selectedUnitFun(unitVal) {
    //   this.lovFieldName = appStrings.FMSLOV.FMSUNIT;
    //   this.createReceiptForm.unitDetails.selectedUnit = unitVal;
    //   this.lovId = unitVal.value;
    //   this.getOtherLovByProjectId();
    // },
    // selectedSubUnitFun(subUnitVal) {
    //   this.lovFieldName = appStrings.FMSLOV.FMSSUBUNIT;
    //   this.createReceiptForm.unitDetails.selectedSubUnit = subUnitVal;
    //   this.lovId = subUnitVal.value;
    //   this.getModuleBasedEntityId();
    //   this.getOtherLovByProjectId();
    //   this.getReceiptCustomer();
    // },
    getReceiptAgainast() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'FMS_SEARCH_BY')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.createReceiptForm.leAndOuDetails.receiptAgainstList = getValueAndText;
            this.createReceiptForm.leAndOuDetails.selectedReceiptAgainst =
              getValueAndText && getValueAndText.length
                ? getValueAndText[0]
                : this.selectedReceiptAgainst;
          }
        });
    },
    getBankList() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'BANK_NAME_VSET')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.bankList = getValueAndText;
          }
        });
    },
    getAdjComponentGroupList() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'FMS_COMP_GROUP_TABLE')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.fms_comp_group_id,
                text: type.fms_comp_group_display_name
              };
            });
            this.fmsCompGroupList = results;
            this.componentGroupList = getValueAndText;
            this.adJustmentDataList[0].componentGroupList = this.componentGroupList;
            this.adJustmentDataList[0].selectedCompoentGroup = this.componentGroupList[0];
            this.selectedComGroup(this.componentGroupList[0], 0);
          }
        });
    },
    getAdjComponentList(valueSetId, index) {
      const payload = {
        lovType: 'FMS_COMP',
        parent_value_set_id: valueSetId
      };
      this.$store.dispatch('party/getLOVBySetType', payload).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const getValueAndText = results.map(type => {
            return {
              value: type.value_code,
              text: type.value_meaning
            };
          });
          this.componentList = getValueAndText;
          this.adJustmentDataList[index].componentList = getValueAndText;
          this.adJustmentDataList[index].selectedCompoent = getValueAndText[0];
        }
      });
    },
    selectReceiptAgainst(option) {
      this.createReceiptForm.leAndOuDetails.selectedReceiptAgainst = option;
      this.isSubunit = this.createReceiptForm.leAndOuDetails.selectedReceiptAgainst.value !== "WSUBUNIT";
      // this.unitDetailsVisible =
      //   this.selectReceiptNature.value === 'identified' &&
      //   option.value === 'SUBUNIT'
      //     ? true
      //     : false;
    },
    // getProjectList() {
    //   const payload = {
    //     typeOfProject: 'FMSPRJ',
    //     le_id: this.createReceiptForm.leAndOuDetails.legalEntity.id
    //   };
    //   this.$store.dispatch('fms/getProjectList', payload).then(response => {
    //     if (response.status === 200) {
    //       const results = response.data.data;
    //       const getValueAndText = results.map(type => {
    //         return {
    //           value: type.proj_id,
    //           text: type.proj_name
    //         };
    //       });
    //       this.createReceiptForm.unitDetails.projectList = getValueAndText;
    //     }
    //   });
    // },
    // getOtherLovByProjectId() {
    //   this.loader = true;
    //   const payload = {
    //     lovFieldName: this.lovFieldName,
    //     lovId: this.lovId
    //   };
    //   this.$store
    //     .dispatch('receipt/getOtherLovByLovFieldAndId', payload)
    //     .then(response => {
    //       this.loader = false;
    //       if (response && response.data && response.data.data) {
    //         const results = response.data.data;
    //         if (this.lovFieldName === appStrings.FMSLOV.FMSPROJECT) {
    //           this.getSectorList(results.sectors);
    //           this.getPocketList(results.pockets);
    //           this.getTowerList(results.towers);
    //           this.getFloorList(results.floors);
    //           this.getUnitList(results.units);
    //           this.getSubUnitList(results.subunits);
    //         } else if (this.lovFieldName === appStrings.FMSLOV.FMSTOWER) {
    //           this.getFloorList(results.floors);
    //           this.getUnitList(results.units);
    //           this.getSubUnitList(results.subunits);
    //         } else if (this.lovFieldName === appStrings.FMSLOV.FMSFLOOR) {
    //           this.getTowerList(results.towers);
    //           this.getUnitList(results.units);
    //           this.getSubUnitList(results.subunits);
    //         } else if (this.lovFieldName === appStrings.FMSLOV.FMSUNIT) {
    //           this.getTowerList(results.towers);
    //           this.getFloorList(results.floors);
    //           this.getSubUnitList(results.subunits);
    //         } else if (this.lovFieldName === appStrings.FMSLOV.FMSSUBUNIT) {
    //           this.getTowerList(results.towers);
    //           this.getFloorList(results.floors);
    //           this.getUnitList(results.units);
    //         }
    //       }
    //     })
    //     .catch(error => {
    //       this.loader = false;
    //       alert(error.message);
    //     });
    // },
    // getSectorList(sectorList) {
    //   if (sectorList && sectorList.length) {
    //     const valueAndTextArr = sectorList.map(type => {
    //       return {
    //         value: type.sector_id,
    //         text: type.sector_name
    //       };
    //     });
    //     this.createReceiptForm.unitDetails.sectorList = valueAndTextArr;
    //     this.createReceiptForm.unitDetails.selectedSector =
    //       valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
    //   } else {
    //     this.createReceiptForm.unitDetails.selectedSector = this.keyValueNoRecord;
    //     this.createReceiptForm.unitDetails.sectorList = [
    //       {
    //         value: null,
    //         text: 'no record found'
    //       }
    //     ];
    //   }
    // },
    // getPocketList(pocketList) {
    //   if (pocketList && pocketList.length) {
    //     const valueAndTextArr = pocketList.map(type => {
    //       return {
    //         value: type.pocket_id,
    //         text: type.pocket_name
    //       };
    //     });
    //     this.createReceiptForm.unitDetails.pocketList = valueAndTextArr;
    //     this.createReceiptForm.unitDetails.selectedPocket =
    //       valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
    //   } else {
    //     this.createReceiptForm.unitDetails.selectedPocket = this.keyValueNoRecord;
    //     this.createReceiptForm.unitDetails.pocketList = [
    //       {
    //         value: null,
    //         text: 'no record found'
    //       }
    //     ];
    //   }
    // },
    // getTowerList(towerList) {
    //   if (towerList && towerList.length) {
    //     const valueAndTextArr = towerList.map(type => {
    //       return {
    //         value: type.tower_id,
    //         text: type.tower_name
    //       };
    //     });
    //     this.createReceiptForm.unitDetails.towerList = valueAndTextArr;
    //     this.createReceiptForm.unitDetails.selectedTower =
    //       valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
    //   } else {
    //     this.createReceiptForm.unitDetails.selectedTower = this.keyValueNoRecord;
    //     this.createReceiptForm.unitDetails.towerList = [
    //       {
    //         value: null,
    //         text: 'no record found'
    //       }
    //     ];
    //   }
    // },
    // getFloorList(floorList) {
    //   if (floorList && floorList.length) {
    //     const valueAndTextArr = floorList.map(type => {
    //       return {
    //         value: type.floor_id,
    //         text: type.floor_name
    //       };
    //     });
    //     this.createReceiptForm.unitDetails.floorList = valueAndTextArr;
    //     this.createReceiptForm.unitDetails.selectedFloor =
    //       valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
    //   } else {
    //     this.createReceiptForm.unitDetails.selectedFloor = this.keyValueNoRecord;
    //     this.createReceiptForm.unitDetails.floorList = [
    //       {
    //         value: null,
    //         text: 'no record found'
    //       }
    //     ];
    //   }
    // },
    // getUnitList(unitList) {
    //   if (unitList && unitList.length) {
    //     const valueAndTextArr = unitList.map(type => {
    //       return {
    //         value: type.unit_id,
    //         text: type.unit_name
    //       };
    //     });
    //     this.createReceiptForm.unitDetails.unitList = valueAndTextArr;
    //     this.createReceiptForm.unitDetails.selectedUnit =
    //       valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
    //   } else {
    //     this.createReceiptForm.unitDetails.selectedUnit = this.keyValueNoRecord;
    //     this.createReceiptForm.unitDetails.unitList = [
    //       {
    //         value: null,
    //         text: 'no record found'
    //       }
    //     ];
    //   }
    // },
    // getSubUnitList(subUnitList) {
    //   if (subUnitList && subUnitList.length) {
    //     const valueAndTextArr = subUnitList.map(type => {
    //       return {
    //         value: type.sub_unit_id,
    //         text: type.sun_unit_name
    //       };
    //     });
    //     this.createReceiptForm.unitDetails.subUnitList = valueAndTextArr;
    //     this.createReceiptForm.unitDetails.selectedSubUnit =
    //       valueAndTextArr.length === 1
    //         ? valueAndTextArr[0]
    //         : this.createReceiptForm.unitDetails.selectedSubUnit;
    //   } else {
    //     this.createReceiptForm.unitDetails.selectedSubUnit = this.keyValueNoRecord;
    //     this.createReceiptForm.unitDetails.unitList = [
    //       {
    //         value: null,
    //         text: 'no record found'
    //       }
    //     ];
    //   }
    // },
    calculateAdjustmentAmount() {
      this.remainingTdsAmout = this.createReceiptForm.instrumentDetails.tdsAmount;
      if (!this.editMode) {
        if (this.selectedApplicationType.value === 'AUTOMATIC') {
          this.outStandingTableFields[0].key = '';
          this.remainingAmout = parseFloat(
            this.createReceiptForm.instrumentDetails.amount
          );
          this.modifiedOutStandingDataList = this.outStandingDataList.map(
            osData => {
              const adjAmount =
                this.remainingAmout >= parseFloat(osData.outstanding_amount)
                  ? osData.outstanding_amount
                  : parseFloat(this.remainingAmout);
              if (
                this.remainingAmout >= parseFloat(osData.outstanding_amount)
              ) {
                this.remainingAmout = (
                  this.remainingAmout - parseFloat(osData.outstanding_amount)
                ).toFixed(2);
              } else {
                this.remainingAmout = 0.0;
              }
              let tdsAmtremain
              if (this.remainingTdsAmout > 0) {
                if (+osData.outstanding_amount - adjAmount <= this.remainingTdsAmout) {
                  tdsAmtremain = osData.outstanding_amount - adjAmount
                  this.remainingTdsAmout = this.remainingTdsAmout - tdsAmtremain
                } else if (+osData.outstanding_amount - adjAmount > this.remainingTdsAmout) {
                  tdsAmtremain = this.remainingTdsAmout;
                  this.remainingTdsAmout = 0.00
                }
              } else {
                tdsAmtremain = 0.00
              }
              return {
                applied_amount: osData.applied_amount,
                customer_id: osData.customer_id,
                customer_site_id: osData.customer_site_id,
                description: osData.description,
                line_type: osData.line_type,
                module_id: osData.module_id,
                outstanding_amount: osData.outstanding_amount,
                trx_amount: osData.trx_amount,
                trx_date: osData.trx_date,
                trx_dtl_id: osData.trx_dtl_id,
                trx_hdr_id: osData.trx_hdr_id,
                trx_number: osData.trx_number,
                adj_amount: adjAmount,
                tds_amount: tdsAmtremain > 0 ? tdsAmtremain.toFixed(2) : tdsAmtremain,
                bill_amount: (
                  osData.outstanding_amount + osData.applied_amount
                ).toFixed(2),
                select: false
              };
            }
          );
          this.modifiedOutStandingDataList = this.modifiedOutStandingDataList.filter(
            key => key.outstanding_amount !== 0
          );
        }
      } else {
        if (
          this.createReceiptForm.customerDetails.customer_id &&
          this.createReceiptForm.customerDetails.customer_site_id
        ) {
          this.getOutStandingList();
        }
        if (this.selectedApplicationType.value === 'AUTOMATIC') {
          this.outStandingTableFields[0].key = '';
        }
      }
    },
    updateAjdAmount(event, index) {
      const osAmout = parseFloat(
        this.modifiedOutStandingDataList[index].outstanding_amount
      );
      const adjAmout = parseFloat(event.target.value);
      if (!isNaN(adjAmout)) {
        if (adjAmout > osAmout) {
          this.showAdjWarning = true;
          this.warningMessage =
            'Adjustable amount can not be greater then Outstanding amount';
          this.modifiedOutStandingDataList[index].adj_amount = 0;
        } else {
          this.showAdjWarning = false;
          this.remainingAmout =
            this.createReceiptForm.instrumentDetails.amount -
            this.getTotalAdjAmout().toFixed(2);
          this.remainingAmout = this.remainingAmout.toFixed(2);
        }
      } else if (event.target.value === '' || event.target.value === null) {
        this.remainingAmout = this.remainingAmout + osAmout;
        this.showAdjWarning = true;
        this.warningMessage = 'Please enter a valid amount';
      }
    },
    getTotalAdjAmout() {
      return this.modifiedOutStandingDataList.reduce(
        (totalValue, currentValue) => {
          return totalValue + currentValue.adj_amount;
        },
        0
      );
    },
    updateTdsAmount(item) {
      if (item.tds_amount <= this.remainingTdsAmout) {
        if (item.tds_amount <= item.outstanding_amount - item.adj_amount) {
          this.remainingTdsAmout = this.createReceiptForm.instrumentDetails.tdsAmount - this.getTotalTdsAmout().toFixed(2);
        } else {
          alert('TDS Amount Can not be greater than Remaining OS Amount')
          item.tds_amount = 0
        }
      } else {
        alert('TDS Amount Can not be greater than Remaining TDS Amount')
        item.tds_amount = 0
      }
    },
    getTotalTdsAmout() {
      return this.modifiedOutStandingDataList.reduce(
        (totalValue, currentValue) => {
          return totalValue + currentValue.tds_amount;
        },
        0
      );
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    setRemainingAmount($event) {
      this.remainingAmout = $event.target.value;
    },
    setTdsAmount($event) {
      this.remainingTdsAmout = $event.target.value;
    },
    calculateAdvanceAmount() {
      if (
        this.remainingAmout > 0 &&
        this.remainingAmout !== null &&
        !this.editMode
      ) {
        this.adJustmentDataList[0].total_amount = this.remainingAmout;
        this.adJustmentDataList[0].amount =
          parseFloat(this.remainingAmout) -
          (parseFloat(this.remainingAmout) *
            this.adJustmentDataList[0].tax_rate) /
          100;
        this.adJustmentDataList[0].tax_amount =
          (parseFloat(this.remainingAmout) *
            this.adJustmentDataList[0].tax_rate) /
          100;
      } else {
        this.getReceiptAdvance();
      }
    },
    checkIntrumentFomrValidation() {
      let validate = false;
      if (
        this.selectedPaymentMode.value !== 'CSH' &&
        this.selectedPaymentMode.value !== 'EFT' &&
        this.selectedPaymentMode.value !== 'TDS Receivable'
      ) {
        validate =
          this.createReceiptForm.instrumentDetails.instrument_num === null ||
          this.selectedBank.value === null;
      }
      return (
        (!this.editMode && !this.selectedPaymentMode.value) ||
        validate ||
        this.createReceiptForm.instrumentDetails.amount === null
      );
    },
    getReceiptAccountingList() {
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/getInvoiceAccountingList', this.receiptHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            if (result.length > 0) {
              this.accountingDetailsList = response.data.data;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateAdvanceAmount(event) {
      const amount = parseFloat(event.target.value);
      this.advanceAmountWarning = false;
      if (amount > this.remainingAmout) {
        this.advanceAmountWarning = true;
        this.warningMessage =
          'Total amount can not be greater than remaining amount';
        this.calculateAdjustmentAmount();
      } else {
        this.adJustmentDataList[0].amount = this.adJustmentDataList[0].tax_rate
          ? (
            amount -
            (amount * this.adJustmentDataList[0].tax_rate) / 100
          ).toFixed(2)
          : null;
        this.adJustmentDataList[0].tax_amount = this.adJustmentDataList[0]
          .tax_rate
          ? ((amount * this.adJustmentDataList[0].tax_rate) / 100).toFixed(2)
          : null;
        this.adJustmentDataList[0].total_amount = amount;
      }
    },
    checkValueForPlan(param) {
      if (this.selectedPlanType.value && param === 'PROJECT') {
        this.openValueSetModal(this.project);
      } else if (this.selectedPlanType.value && param === 'TOWER') {
        if (this.selectedProject.value) {
          this.openValueSetModal(this.tower);
        } else {
          alert('Please Select Project');
        }
      } else if (this.selectedPlanType.value && param === 'FLOOR') {
        if (this.selectedProject.value) {
          this.openValueSetModal(this.floor);
        } else {
          alert('Please Select Project');
        }
      } else if (this.selectedPlanType.value && param === 'UNIT') {
        if (this.selectedProject.value) {
          this.openValueSetModal(this.unit);
        } else {
          alert('Please Select Project');
        }
      } else if (this.selectedPlanType.value && param === 'NFA_FMS_SUB_UNIT') {
        if (this.selectedProject.value) {
          this.openValueSetModal('NFA_FMS_SUB_UNIT');
        } else {
          alert('Please Select Project');
        }
      } else {
        alert('Please Select Plan Type');
      }
    },
    clearSearch() {
      this.selectedPlanType = {
        value: null,
        text: null
      };
      this.selectedProject = {
        value: null,
        text: null
      };
      this.createReceiptForm.unitDetails.selectedTower = {
        value: null,
        text: null
      };
      this.createReceiptForm.unitDetails.selectedFloor = {
        value: null,
        text: null
      };
      this.createReceiptForm.unitDetails.selectedUnit = {
        value: null,
        text: null
      };
      this.createReceiptForm.unitDetails.selectedSubUnit = {
        value: null,
        text: null
      };
    },
    clearVsetValues(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE[this.project]) {
        this.selectedProject = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE[this.tower]) {
        this.createReceiptForm.unitDetails.selectedTower = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE[this.floor]) {
        this.createReceiptForm.unitDetails.selectedFloor = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE[this.unit]) {
        this.createReceiptForm.unitDetails.selectedUnit = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.createReceiptForm.unitDetails.selectedSubUnit = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PLAN_UNIT_TYPE) {
        this.selectedPlanType = {
          value: null,
          text: null
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE[this.project]) {
        this.depVsetParam = this.createReceiptForm.leAndOuDetails.legalEntity.id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PLAN_UNIT_TYPE) {
        this.parent_value_set_id = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE[this.tower]) {
        this.parent_value_set_id = this.selectedProject.value;
        this.depVsetParam = this.selectedProject.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE[this.floor]) {
        this.parent_value_set_id = this.createReceiptForm.unitDetails.selectedTower.value;
        this.depVsetParam = this.selectedProject.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE[this.unit]) {
        this.parent_value_set_id = this.createReceiptForm.unitDetails.selectedFloor.value;
        this.depVsetParam = this.selectedProject.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.parent_value_set_id = this.createReceiptForm.unitDetails.selectedUnit.value;
        this.depVsetParam = this.selectedProject.value;
      } else if (this.vsetCode == appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.createReceiptForm.leAndOuDetails.legalEntity.id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.parent_value_set_id = this.createReceiptForm.leaseUnitDetails.leaseProject.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.parent_value_set_id = this.createReceiptForm.leaseUnitDetails.leaseTower.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST) {
        this.parent_value_set_id = this.createReceiptForm.leaseUnitDetails.leaseFloor.value;
      } else {
        this.parent_value_set_id = null;
      }
      this.showValueSetModal = true;
      this.setTimeout = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.selectedModule.name === 'FMS') {
        if (this.vsetCode === appStrings.VALUESETTYPE[this.project]) {
          this.selectedProject.value = item.value_set_dtl_id;
          this.selectedProject.text = item.value_code;
          if (this.project === 'LEASE_PRJ_USING_FMS') {
            this.leaseByFmsPrjId = item.fms_prj_id;
          }
          this.createReceiptForm.unitDetails.selectedTower = {
            value: null,
            text: null
          };
          this.createReceiptForm.unitDetails.selectedFloor = {
            value: null,
            text: null
          };
          this.createReceiptForm.unitDetails.selectedUnit = {
            value: null,
            text: null
          };
          this.createReceiptForm.unitDetails.selectedSubUnit = {
            value: null,
            text: null
          };
          this.createReceiptForm.entity_id = this.selectedProject.value;
          this.getModuleBasedEntityId();
          this.getProjectBasedCustomerSites();
        } else if (this.vsetCode === appStrings.VALUESETTYPE.PLAN_UNIT_TYPE) {
          this.selectedPlanType.value = item.value_code;
          this.selectedPlanType.text = item.value_meaning;
          this.selectedProject = {
            value: null,
            text: null
          };
          this.selectedPlanType.value === 'LEASE_UNIT'
            ? (this.project = 'LEASE_PRJ_USING_FMS')
            : (this.project = 'NFA_FMS_PROJECT');
          this.selectedPlanType.value === 'LEASE_UNIT'
            ? (this.tower = 'LEASE_TOWER_LIST')
            : (this.tower = 'NFA_FMS_TOWER');
          this.selectedPlanType.value === 'LEASE_UNIT'
            ? (this.floor = 'LEASE_FLOOR_LIST')
            : (this.floor = 'NFA_FMS_FLOOR');
          this.selectedPlanType.value === 'LEASE_UNIT'
            ? (this.unit = 'LEASE_UNIT_LIST')
            : (this.unit = 'NFA_FMS_UNIT');
        } else if (this.vsetCode === appStrings.VALUESETTYPE[this.tower]) {
          this.createReceiptForm.unitDetails.selectedTower = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.createReceiptForm.unitDetails.selectedFloor = {
            value: null,
            text: null
          };
          this.createReceiptForm.unitDetails.selectedUnit = {
            value: null,
            text: null
          };
          this.createReceiptForm.unitDetails.selectedSubUnit = {
            value: null,
            text: null
          };
        } else if (this.vsetCode === appStrings.VALUESETTYPE[this.floor]) {
          this.selectedPlanType.value === 'LEASE_UNIT'
            ? (this.createReceiptForm.unitDetails.selectedTower = {
              value: item.lease_tower_id,
              text: item.lease_tower_name
            })
            : (this.createReceiptForm.unitDetails.selectedTower = {
              value: item.tower_id,
              text: item.tower_name
            });
          this.createReceiptForm.unitDetails.selectedFloor = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.createReceiptForm.unitDetails.selectedUnit = {
            value: null,
            text: null
          };
          this.createReceiptForm.unitDetails.selectedSubUnit = {
            value: null,
            text: null
          };
        } else if (this.vsetCode === appStrings.VALUESETTYPE[this.unit]) {
          this.selectedPlanType.value === 'LEASE_UNIT'
            ? (this.createReceiptForm.unitDetails.selectedTower = {
              value: item.lease_tower_id,
              text: item.lease_tower_name
            })
            : (this.createReceiptForm.unitDetails.selectedTower = {
              value: item.tower_id,
              text: item.tower_name
            });
          this.selectedPlanType.value === 'LEASE_UNIT'
            ? (this.createReceiptForm.unitDetails.selectedFloor = {
              value: item.lease_floor_id,
              text: item.lease_floor_name
            })
            : (this.createReceiptForm.unitDetails.selectedFloor = {
              value: item.floor_id,
              text: item.floor_name
            });
          this.createReceiptForm.unitDetails.selectedUnit = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.createReceiptForm.unitDetails.selectedSubUnit = {
            value: null,
            text: null
          };
        } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
          this.createReceiptForm.unitDetails.selectedSubUnit = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.getModuleBasedEntityId();
          this.getReceiptCustomer();
          this.createReceiptForm.unitDetails.selectedTower = {
            value: item.tower_id,
            text: item.tower_name
          };
          this.createReceiptForm.unitDetails.selectedFloor = {
            value: item.floor_id,
            text: item.floor_name
          };
          this.createReceiptForm.unitDetails.selectedUnit = {
            value: item.unit_id,
            text: item.unit_name
          };
        }
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.PAYMENT_MODE) {
        this.selectedPaymentMode.value = item.value_code;
        this.selectedPaymentMode.text = item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BANK_NAME_VSET) {
        this.selectedBank.value = item.value_code;
        this.selectedBank.text = item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.createReceiptForm.leAndOuDetails.legalEntity.id = item.org_id;
        this.createReceiptForm.leAndOuDetails.legalEntity.text = item.org_name;
        this.createReceiptForm.leAndOuDetails.orgUnit.text = null;
        this.createReceiptForm.leAndOuDetails.orgUnit.id = null;
        this.createReceiptForm.leaseUnitDetails.leaseProject.text = null;
        this.createReceiptForm.leaseUnitDetails.leaseProject.value = null;
        // this.getProjectList();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.createReceiptForm.leAndOuDetails.orgUnit.text = item.org_name;
        this.createReceiptForm.leAndOuDetails.orgUnit.id = item.org_id;
        if (this.createReceiptForm.leAndOuDetails.selectedReceiptAgainst.value === 'WSUBUNIT') {
          this.getReceiptCustomer();
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.createReceiptForm.leaseUnitDetails.leaseProject.text =
          item.value_code;
        this.createReceiptForm.leaseUnitDetails.leaseProject.value =
          item.value_set_dtl_id;
        this.createReceiptForm.leAndOuDetails.legalEntity.id = item.le_id;
        this.createReceiptForm.leAndOuDetails.legalEntity.text = item.le_name;
        this.createReceiptForm.leAndOuDetails.orgUnit.text = item.ou_name;
        this.createReceiptForm.leAndOuDetails.orgUnit.id = item.ou_id;
        this.getModuleBasedEntityId();
        this.getProjectBasedCustomerSites();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.createReceiptForm.leaseUnitDetails.leaseTower.text =
          item.value_code;
        this.createReceiptForm.leaseUnitDetails.leaseTower.value =
          item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.createReceiptForm.leaseUnitDetails.leaseFloor.text =
          item.value_code;
        this.createReceiptForm.leaseUnitDetails.leaseFloor.value =
          item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST) {
        this.createReceiptForm.leaseUnitDetails.leaseUnit.text =
          item.value_code;
        this.createReceiptForm.leaseUnitDetails.leaseUnit.value =
          item.value_set_dtl_id;
        this.getModuleBasedEntityId();
        this.getReceiptCustomer();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.RECEIPT_TYPE) {
        this.createReceiptForm.instrumentDetails.receipt_type_meaning =
          item.value_meaning;
        this.createReceiptForm.instrumentDetails.receipt_type_vset =
          item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.RECEIPT_REVERSAL_REASON
      ) {
        this.reverseReason = item.value_meaning;
        this.reverseReasonVsetCode = item.value_code;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    outStandingRowSelected() { },
    hideModuleModal(flag) {
      this.showHideModule = flag;
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.showHideModule = false;
      this.getTrxEntityTypeByModuleId(this.selectedModule.id);
    },
    getModuleBasedEntityId() {
      // this.createReceiptForm.entity_id =
      //   this.createReceiptForm.entity_type === 'FMS_SUB_UNIT'
      //     ? this.createReceiptForm.unitDetails.selectedSubUnit.value
      //     : this.selectedProject.value;
      if (this.selectedModule.name === 'FMS') {
        this.createReceiptForm.entity_id = this.selectedProject.value;
      } else {
        this.createReceiptForm.entity_id = this.createReceiptForm.leaseUnitDetails.leaseProject.value;
      }
    },
    getRemainingAmountForOs() {
      const payload = {
        trx_type: 'RECEIPT',
        trx_id: this.createReceiptForm.receipt_hdr_id,
        attribute: 'UNAPPLIED'
      };
      this.$store
        .dispatch('receivables/getRemainingAmountForOs', payload)
        .then(resp => {
          if (resp.status === 200) {
            const amount = resp.data.data;
            this.remainingAmout = amount ? parseFloat(amount.toFixed(2)) : 0.0;
          } else {
            this.remainingAmout = this.createReceiptForm.instrumentDetails.amount;
          }
        });
    },
    getAllLeaseUnits() {
      this.$store.dispatch('receivables/getLeaseUnits').then(resp => {
        const result = resp.data.data;
        this.createReceiptForm.leaseUnitDetails.leaseUnitList = result.map(
          key => {
            return {
              value: key.lease_prj_unit_id,
              text: key.unit_name
            };
          }
        );
      });
    },
    selectedLeaseUnitFun(event) {
      this.createReceiptForm.leaseUnitDetails.leaseUnit = event;
      this.getLeaseUnitDetails(event.value);
      this.getModuleBasedEntityId();
      this.getReceiptCustomer();
    },
    getLeaseUnitDetails(lease_prj_unit_id) {
      this.$store
        .dispatch('receivables/getLeaseProjectTowerFloor', lease_prj_unit_id)
        .then(resp => {
          const result = resp.data.data;
          this.createReceiptForm.leaseUnitDetails.leaseProject = {
            value: result.lease_prj_id,
            text: result.lease_prj_name
          };
          this.createReceiptForm.leaseUnitDetails.leaseTower = {
            value: result.lease_tower_id,
            text: result.tower_name
          };
          this.createReceiptForm.leaseUnitDetails.leaseFloor = {
            value: result.lease_floor_id,
            text: result.floor_name
          };
        });
    },
    getProjectBasedCustomerSites() {
      this.customerList = [];
      this.createReceiptForm.customerDetails = {
        customer_name: null,
        customer_id: null,
        complete_address: null,
        address_id: null,
        site_name: null,
        customer_site_id: null,
        lease_prj_unit_id: null,
        unit_name: null
      };
      const payload = {
        lease_prj_id: this.createReceiptForm.leaseUnitDetails.leaseProject.value
          ? this.createReceiptForm.leaseUnitDetails.leaseProject.value
          : this.selectedProject.value,
        searchParams: {
          _page: this.customerCurrentPage - 1,
          _limit: this.customerPerPage,
          customer: this.customerName,
          all: this.allCustomer
        }
      };
      this.loader = true;
      this.$store
        .dispatch('receivables/getLeaseProjectBasedCustomerSites', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.isBusy = false;
            const result = resp.data.data.page;
            this.customerList = result;
            this.customerTotalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedCustomerRow(item) {
      if (this.selectedModule.name === 'FMS') {
        this.createReceiptForm.customerDetails = {
          customer_name: item.customer_name,
          customer_id: item.customer_id,
          complete_address: item.bill_to_address,
          address_id: item.bill_to_address_id,
          site_name: item.customer_site_name,
          customer_site_id: item.customer_site_id,
          lease_prj_unit_id: item.lease_prj_unit_id,
          unit_name: item.unit_name
        };
        this.getOutStandingList();
      } else if (this.selectedModule.name === 'Lease Management') {
        this.createReceiptForm.customerDetails = {
          customer_name: item.customer_name,
          customer_id: item.customer_id,
          complete_address: item.bill_to_address,
          address_id: item.bill_to_address_id,
          site_name: item.customer_site_name,
          customer_site_id: item.customer_site_id,
          lease_prj_unit_id: null,
          unit_name: null
        };
        this.getOutStandingList();
      }
      this.showCustomerModal = false;
    },
    validateReceiptForm(field_name) {
      switch (field_name) {
        case 'legal_entity':
          if (this.selectedModule.name) {
            this.openValueSetModal(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
          } else {
            alert('Please select Module!');
          }
          break;
        case 'operating_unit':
          this.openValueSetModal(appStrings.VALUESETTYPE.OU_LIST);
          break;
        case 'lease_project':
          this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
          break;
        case 'customer':
          if (
            this.createReceiptForm.entity_id ||
            this.createReceiptForm.leaseUnitDetails.leaseProject.text
          ) {
            this.openCustomerModal(true);
          } else {
            if (this.selectedModule.name === 'FMS' && this.createReceiptForm.leAndOuDetails.selectedReceiptAgainst.value === 'WSUBUNIT') {
              this.openCustomerModal(true);
            }
            else if (
              this.selectedModule.name === 'FMS' &&
              !this.createReceiptForm.entity_id
            ) {
              alert('Please select FMS Sub Unit!');
            } else if (
              this.selectedModule.name === 'Lease Management' &&
              !this.createReceiptForm.leaseUnitDetails.leaseProject.text
            ) {
              alert('Please select Lease Project!');
            } else if (!this.selectedModule.name) {
              alert('Please select Module!');
            }
          }
          break;
      }
    },
    getTdsReceiptMethodsData() {
      if (
        !this.editMode ||
        (this.editMode &&
          this.createReceiptForm.instrumentDetails.tdsReceiptMethod.value)
      ) {
        const payload = {
          module_id: this.selectedModule.id,
          le_id: this.createReceiptForm.leAndOuDetails.legalEntity.id
            ? this.createReceiptForm.leAndOuDetails.legalEntity.id
            : null,
          project_id: this.selectedProject.value
            ? this.selectedPlanType.value === 'LEASE_UNIT'
              ? this.leaseByFmsPrjId
              : this.selectedProject.value
            : this.createReceiptForm.leaseUnitDetails.leaseProject.value,
          receipt_mode_vset_code: 'TDS Receivable',
          tds_receipt_method_id: this.createReceiptForm.instrumentDetails
            .tdsReceiptMethod.value
        };
        this.loader = true;
        this.$store
          .dispatch('receivables/getReceiptMethodsDetail', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              const result = response.data.data;
              this.createReceiptForm.instrumentDetails.tdsReceiptMethodList = result.map(
                method => {
                  return {
                    value: method.party_receipt_method_id,
                    text: method.receipt_methods_name
                  };
                }
              );
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    applyManualAmt(event, index) {
      let modifiedOsData = this.modifiedOutStandingDataList[index];
      modifiedOsData.select = event;
      const adjAmount =
        this.remainingAmout >= parseFloat(modifiedOsData.outstanding_amount)
          ? modifiedOsData.outstanding_amount
          : parseFloat(this.remainingAmout);
      if (event) {
        if (
          this.remainingAmout >= parseFloat(modifiedOsData.outstanding_amount)
        ) {
          this.remainingAmout = (
            this.remainingAmout - parseFloat(modifiedOsData.outstanding_amount)
          ).toFixed(2);
        } else {
          this.remainingAmout = 0.0;
        }
        modifiedOsData.adj_amount = adjAmount;
      } else {
        this.remainingAmout =
          parseFloat(this.remainingAmout) +
          parseFloat(modifiedOsData.adj_amount);
        modifiedOsData.adj_amount = 0;
      }
    },
    checkTdsAmountParam() {
      if (
        (this.createReceiptForm.instrumentDetails.tdsAmount &&
          this.createReceiptForm.instrumentDetails.tdsReceiptMethod.value) ||
        (!this.createReceiptForm.instrumentDetails.tdsAmount &&
          !this.createReceiptForm.instrumentDetails.tdsReceiptMethod.value)
      ) {
        return true;
      }
      return false;
    },
    setDefaultReceiptType() {
      const receiptType = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.RECEIPT_TYPE
      );
      receiptType.then(key => {
        this.createReceiptForm.instrumentDetails.receipt_type_meaning =
          key[0].value_meaning;
        this.createReceiptForm.instrumentDetails.receipt_type_vset =
          key[0].value_code;
      });
    },
    setDefaultPaymentMode() {
      const paymentMode = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.PAYMENT_MODE
      );
      paymentMode.then(key => {
        this.selectedPaymentMode = {
          value: key[0].value_code,
          text: key[0].value_meaning
        };
      });
    },
    createNewReceipt() {
      this.remainingAmout = 0.0;
      this.totalOsAmout = null;
      this.customerPerPage = commonHelper.perPageRecord;
      this.customerCurrentPage = 1;
      this.customerTotalRows = null;
      this.selectReceiptNature = {
        value: 'identified',
        text: 'Identified'
      };
      this.receiptNatureList = [
        {
          value: 'identified',
          text: 'Identified'
        },
        {
          value: 'unidentified',
          text: 'Unidentified'
        }
      ];
      this.selectedModule = {
        name: null,
        id: null
      };
      this.createReceiptForm.leaseUnitDetails.leaseProject = {
        value: null,
        text: null
      };
      this.createReceiptForm.leAndOuDetails.legalEntity = {
        text: null,
        id: null
      };
      this.createReceiptForm.leAndOuDetails.orgUnit = {
        text: null,
        id: null
      };
      this.customerList = [];
      this.createReceiptForm.customerDetails = {
        customer_name: null,
        customer_id: null,
        complete_address: null,
        address_id: null,
        site_name: null,
        customer_site_id: null,
        lease_prj_unit_id: null,
        unit_name: null
      };
      this.createReceiptForm.instrumentDetails.receipt_num = null;
      this.createReceiptForm.instrumentDetails.instrument_num = null;
      this.createReceiptForm.instrumentDetails.branch = null;
      this.createReceiptForm.instrumentDetails.narration = null;
      this.createReceiptForm.instrumentDetails.instrument_status = null;
      this.createReceiptForm.instrumentDetails.clearing_date = null;
      this.createReceiptForm.instrumentDetails.reason = null;
      this.createReceiptForm.instrumentDetails.amount = null;
      this.createReceiptForm.instrumentDetails.tdsAmount = null;
      this.createReceiptForm.instrumentDetails.tdsReceiptMethod = {
        text: null,
        value: null
      };
      this.createReceiptForm.instrumentDetails.tdsReceiptNo = null;
      this.createReceiptForm.instrumentDetails.exchange_rate = 1;
      this.createReceiptForm.instrumentDetails.exchange_type = 'Corporate';
      this.createReceiptForm.instrumentDetails.value_date = format(
        new Date(),
        appStrings.DATEFNSFORMAT
      );
      this.createReceiptForm.instrumentDetails.gl_date = format(
        new Date(),
        appStrings.DATEFNSFORMAT
      );
      this.createReceiptForm.instrumentDetails.instrument_date = format(
        new Date(),
        appStrings.DATEFNSFORMAT
      );
      this.setDefaultReceiptType();
      this.setDefaultPaymentMode();
      this.getReceiptMethodsData();
      this.getReceiptApplicatonTypes();
      this.modifiedOutStandingDataList = [];
      this.adJustmentDataList = [
        {
          category_name: null,
          receipt_hdr_id: null,
          tax_cat_id: null,
          receipt_adv_id: 0,
          amount: 0.0
        }
      ];
    },
    initiateReverseReceipt() {
      const payload = {
        receipt_hdr_id: this.receiptHdrId,
        reverse_payload: {
          reversal_date: this.reverseDate,
          reversal_reason: this.reverseReasonVsetCode,
          reversal_comment: this.reverseRemark
        }
      };
      this.loader = true;
      this.$store
        .dispatch('receivables/reverseReceipt', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.isReveseSuccess = true;
            this.showReveseAlert = true;
            this.reverseResponseMsg = response.data.message;
            this.reverseResponseText = response.data.data;
          } else {
            this.isReveseSuccess = false;
            this.showReveseAlert = true;
            this.reverseResponseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.isReveseSuccess = false;
          this.showReveseAlert = true;
          this.reverseResponseMsg = err.message;
        });
    },
    hideReverseReceiptModal() {
      this.reverseReason = null;
      this.reverseReasonVsetCode = null;
      this.reverseDate = null;
      this.reverseRemark = null;
      this.isReveseSuccess = false;
      this.showReveseAlert = false;
      this.reverseResponseMsg = '';
      this.reverseResponseText = null;
    },

    getReceiptNotesById() {
      this.loader = true;
      this.$store
        .dispatch('receivables/getReceiptNotesById', this.receiptHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.length > 0) {
              this.arReceiptNotesList = response.data.data;
            }
          } else {
            this.isReveseSuccess = false;
            this.showReveseAlert = true;
            this.reverseResponseMsg = response.response.data.message;
          }
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.arReceiptNotesList.push({
          note_id: 0,
          created_by: this.getUserName(),
          notes: null,
          creation_date: commonHelper.formattedDate(new Date())
        });
      }
    },

    addEditReceiptNotes() {
      const noteArray = this.arReceiptNotesList.map(
        // map is for-each
        elem => {
          return {
            note_id: elem.note_id,
            notes: elem.notes
          };
        }
      );

      const receiptNotesPayload = {
        record_id: this.receiptHdrId,
        data: noteArray ? noteArray : null
      };
      this.$store
        .dispatch('receivables/addEditReceiptNotes', receiptNotesPayload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    removeRow(index) {
      if (this.editMode) {
        this.arReceiptNotesList.splice(index, 1);
      }
    },
    showHideApplyReceiptModal(flag) {
      this.showApplyReceiptModal = flag;
      // this.sendObj = this.createReceiptForm;
    }
  },
  destroyed() {
    this.$store.dispatch('shared/setUnsetOrgType', null);
  },
  beforeDestroy() {
    clearTimeout(this.setTimeout);
    this.unsubscribe();
  }
};
